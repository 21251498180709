import React from "react";
import Youtube from "../components/Youtube/Youtube";

function YoutubePage(){
    return(
        <div>
            <div>Youtube</div>
            <Youtube />
        </div>  
    );
}

export default YoutubePage;